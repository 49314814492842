<template>
  <!-- 落地页数据 -->
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">企业订单</div>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1000 }"
        @change="handlePaginationChange"
      >
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statusText[record.status].status"
            :text="statusText[record.status].text"
          />
        </template>
        <template slot="action" slot-scope="record">
          <a-button
            type="link"
            @click="onAction(record.id)"
            :disabled="record.status === 1"
          >
            记录回访
          </a-button>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      statusText: [
        {
          status: 'success',
          text: '待回访'
        },
        {
          status: 'default',
          text: '已回访'
        }
      ],
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '企业/单位名称',
          width: 200,
          dataIndex: 'company'
        },
        {
          title: '联系人/经手人',
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '联系方式',
          width: 150,
          dataIndex: 'mobile'
        },
        {
          title: '订单需求概括',
          dataIndex: 'content'
        },
        {
          title: '回访状态',
          width: 100,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 130,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getEnterpriseOrder()
  },
  methods: {
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getEnterpriseOrder()
    },
    getEnterpriseOrder() {
      // 获取企业订单
      const data = {
        page: this.pagination.current,
        limit: 10
      }
      this.$axios.getEnterpriseOrder(data).then((res) => {
        const d = res.data.data
        this.dataSource = d.list
        this.pagination.total = d.count
        this.loading = false
      })
    },
    onAction(id) {
      // 企业订单记录回访
      this.$confirm({
        title: '确定记录回访？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: id,
            status: 1
          }
          this.$axios.recordEnterpriseOrder(data).then(() => {
            this.$message.success('操作成功')
            this.getEnterpriseOrder()
          })
        }
      })
    }
  }
}
</script>

<style></style>
